import { Cursor as CursorCore } from '@studiometa/ui';

/**
 * Cursor
 */
export default class Cursor extends CursorCore {
  static config = {
    ...CursorCore.config,
    options: {
      ...CursorCore.config.options,
      activeBreakpoints: {
        type: String,
        default: 'm l xl xxl xxxl',
      },
    },
  };

  /**
   * Moved hook.
   * @param {object} props service props
   * @param {Event} props.event
   * @param {number} props.x
   * @param {number} props.y
   * @param {boolean} props.isDown
   */
  moved({ event, x, y, isDown }) {
    if (!this.$services.has('ticked')) {
      this.$services.enable('ticked');
    }

    this.pointerX = x;
    this.pointerY = y;

    let { scale } = this.$options;

    if (!event) {
      this.pointerScale = scale;
      return;
    }

    const shouldGrow =
      (event.target instanceof Element && event.target.matches(this.$options.growSelectors)) ||
      false;
    const shouldReduce =
      isDown ||
      (event.target instanceof Element && event.target.matches(this.$options.shrinkSelectors)) ||
      false;

    if (shouldGrow) {
      scale = this.$options.growTo;
      this.$el.classList.add('is-grow');
    } else {
      this.$el.classList.remove('is-grow');
    }

    if (shouldReduce) {
      scale = this.$options.shrinkTo;
    }

    this.pointerScale = scale;
  }
}
