/* eslint-disable consistent-return, no-extend-native */
import { isFunction } from '@studiometa/js-toolkit/utils';

if (!isFunction(Array.prototype.at)) {
  Object.defineProperty(Array.prototype, 'at', {
    /**
     * At polyfill.
     * @param   {string|number} index
     * @returns {any|undefined}
     */
    value: function at(index) {
      const indexAsNumber = Number(index);

      if (indexAsNumber >= this.length || indexAsNumber < -this.length) {
        return;
      }

      if (indexAsNumber < 0) {
        return this[indexAsNumber + this.length];
      }

      return this[indexAsNumber];
    },
    configurable: true,
  });
}
