import { Base } from '@studiometa/js-toolkit';

/**
 * @typedef {{}} ComponentProps
 */

/**
 * Component component.
 * @extends {Base<ComponentProps>}
 */
export default class TypeWriter extends Base {
  words = [
    'CRO',
    'TMA/TME',
    'REFONTE',
    'UX',
    'MIGRATION',
    'A/B TEST',
    'DEV',
    'STRATEGIE',
    'E-COMMERCE',
    'BRANDING',
  ];

  wordsIndex = 0;

  /**
   * Class config.
   * @type {import('@studiometa/js-toolkit').BaseConfig}
   */
  static config = {
    name: 'TypeWriter',
  };

  /**
   * Mount component
   * @returns {void}
   */
  mounted() {
    setInterval(() => {
      this.changeWord();
    }, 3000);
  }

  /**
   * Erase current word
   * @returns {void}
   */
  eraseAndWriteWord() {
    let currentWord = this.$el.textContent;
    const eraseInterval = setInterval(() => {
      if (currentWord.length > 0) {
        currentWord = currentWord.slice(0, Math.max(0, currentWord.length - 1));
        this.$el.textContent = currentWord;
      } else {
        clearInterval(eraseInterval);
        this.writeWord();
      }
    }, 50);
  }

  /**
   * Write new word
   * @returns {void}
   */
  writeWord() {
    let index = 0;
    const interval = setInterval(() => {
      if (index <= this.newWord.length) {
        this.$el.textContent = this.newWord.slice(0, Math.max(0, index));
        index += 1;
      } else {
        clearInterval(interval);
      }
    }, 50);
  }

  /**
   * Change word in tag
   * @returns {void}
   */
  changeWord() {
    this.wordsIndex += 1;
    this.wordsIndex = this.wordsIndex > this.words.length - 1 ? 0 : this.wordsIndex;
    this.newWord = this.words[this.wordsIndex];
    this.eraseAndWriteWord();
  }
}
