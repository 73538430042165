import { ScrollReveal as ScrollRevealCore, Transition } from '@studiometa/ui';

/**
 * ScrollReveal component
 */
export default class ScrollReveal extends ScrollRevealCore {
  /**
   * Component Configuration
   */
  static config = {
    name: 'ScrollReveal',
    options: {
      ...Transition.config.options,
      enterFrom: {
        type: String,
        default: 'opacity-0 translate-y-12',
      },
      enterActive: {
        type: String,
        default: 'transition duration-1000 ease-out-quad',
      },
      intersectionObserver: {
        type: Object,
        default: () => ({
          threshold: [0, 1],
          rootMargin: '-100px',
        }),
      },
    },
  };
}
