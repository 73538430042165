import { Base } from '@studiometa/js-toolkit';
import Logos from './Logos.js';

/**
 * @typedef {{}} ComponentProps
 */

/**
 * Component component.
 * @extends {Base<ComponentProps>}
 */
export default class RandomLogo extends Base {
  excludedIndexs = [0, 1, 2, 3, 4];

  launchTimer = [0, 500, 750, 1000, 1250];

  /**
   * Class config.
   * @type {import('@studiometa/js-toolkit').BaseConfig}
   */
  static config = {
    name: 'RandomLogo',
    components: {
      Logos,
    },
  };

  /**
   * Mount component
   * @returns {void}
   */
  mounted() {
    this.$children.Logos.forEach((element, index) => {
      element.startAnimation(this.launchTimer[index]);
    });
  }
}
