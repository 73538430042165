import { Base } from '@studiometa/js-toolkit';

/**
 * Anchors
 */
export default class Anchors extends Base {
  /**
   * @type {import('@studiometa/js-toolkit').BaseConfig}
   */
  static config = {
    name: 'Anchors',
    refs: ['anchor[]'],
  };

  elements = [];

  /**
   * mounted hook
   */
  mounted() {
    this.elements = this.$refs.anchor.map((anchor) => {
      return document.querySelector(anchor.getAttribute('href'));
    });
  }

  /**
   * scrolled hook
   */
  scrolled() {
    this.$el.classList.add('is-visible');
    // @ts-ignore
    this.elements.sort(this.sorter);

    // Remove the `is-active` class each element
    this.elements.forEach((element, i) => {
      this.$refs.anchor[i].classList.remove('opacity-100');
      this.$refs.anchor[i].classList.add('opacity-50');
    });

    // Add the `is-active` class on the first matching
    const active = this.$refs.anchor.find((anchor) => {
      return anchor.getAttribute('href') === `#${this.elements[0].getAttribute('id')}`;
    });

    if (active) {
      active.classList.add('opacity-100');
      active.classList.remove('opacity-50');
    }
  }

  /**
   * Sort elements by their position
   * to the middle of the viewport
   * @param  {object} el1 The first DOM element
   * @param  {object} el2 The second DOM element
   * @returns {boolean}    Is it the closest?
   */
  sorter(el1, el2) {
    const el1Size = el1.getBoundingClientRect();
    const el2Size = el2.getBoundingClientRect();
    const pos1 = Math.abs(el1Size.top + el1Size.height / 2 - window.innerHeight / 2);
    const pos2 = Math.abs(el2Size.top + el2Size.height / 2 - window.innerHeight / 2);

    // @ts-ignore
    return pos1 - pos2;
  }
}
