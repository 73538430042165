import { Base } from '@studiometa/js-toolkit';

/**
 * @typedef {{}} ComponentProps
 */

/**
 * Component component.
 * @extends {Base<ComponentProps>}
 */
export default class Logos extends Base {
  previousIndex = 0;

  /**
   * Class config.
   * @type {import('@studiometa/js-toolkit').BaseConfig}
   */
  static config = {
    name: 'Logos',
    refs: ['container'],
  };

  /**
   * Start Interval
   * @param  {number} delay delay for starting animation
   * @returns {void}
   */
  startAnimation(delay) {
    setTimeout(() => {
      setInterval(() => {
        this.changeLogos(0, 9);
      }, 4000);
    }, delay);
  }

  /**
   * Do translation
   * @param  {number} min min value.
   * @param  {number} max max value.
   * @returns {void}
   */
  changeLogos(min, max) {
    const randomIndex = [];
    for (let i = min; i <= max; i += 1) {
      if (!this.$parent.excludedIndexs.includes(i)) {
        randomIndex.push(i);
      }
    }
    // Sélection d'un nombre aléatoire parmi les nombres possibles
    const index = Math.floor(Math.random() * randomIndex.length);
    this.$parent.excludedIndexs.push(randomIndex[index]);

    if (randomIndex[index] !== undefined) {
      this.$refs.container.style.transform = `translateX(-${randomIndex[index] * 100}%)`;
    } else {
      this.$parent.excludedIndexs = [];
    }
  }
}
