import './polyfills.js';
import { Base, createApp, importWhenVisible } from '@studiometa/js-toolkit';
import { LargeText } from '@studiometa/ui';
import Slider from './components/Slider.js';
import Cursor from './components/Cursor.js';
import Cards from './components/Cards.js';
import ScrollAnimationParent from './components/ScrollAnimationParent.js';
import Testimonies from './components/Testimonies.js';
import ScrollReveal from './components/ScrollReveal.js';
import RandomLogo from './components/RandomLogo.js';
import TypeWriter from './components/TypeWriter.js';
import Anchors from './components/Anchors.js';

/**
 * @typedef {{
 *   $children: {
 *     LargeText: LargeText[]
 *   }
 * }} AppProps
 */

/**
 * App class.
 * @extends {Base<AppProps>}
 */
class App extends Base {
  /**
   * Config.
   * @type {import('@studiometa/js-toolkit').BaseConfig}
   */
  static config = {
    name: 'App',
    components: {
      LargeText,
      Cursor,
      Cards,
      ScrollAnimationParent,
      Slider,
      Testimonies,
      ScrollReveal,
      RandomLogo,
      TypeWriter,
      Anchors,
      Accordion: (app) =>
        importWhenVisible(() => import('./components/Accordion.js'), 'Accordion', app),
    },
  };
}

export default createApp(App, {
  features: {
    asyncChildren: true,
  },
});
