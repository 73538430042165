import { Base } from '@studiometa/js-toolkit';
import { Sentinel } from '@studiometa/ui';

/**
 * @typedef {{
 *   $refs: {
 *     sentinelTarget: HTMLElement[]
 *     sentinelRef: HTMLElement[]
 *   }
 * }} Props
 */

/**
 * App class.
 * @extends {Base<Props>}
 */
export default class Testimonies extends Base {
  /**
   * Component Configuration
   */
  static config = {
    name: 'Testimonies',
    refs: ['sentinelTarget[]', 'sentinelRef[]'],
    components: {
      Sentinel,
    },
  };

  index = 0;

  root = null;

  /**
   * mounted hook
   */
  mounted() {
    this.root = document.querySelector(':root');
    this.root.style.setProperty('--current-testimony', 0);
  }

  /**
   * onSentinelIntersected
   * @param {*} param0
   */
  onSentinelIntersected([entry]) {
    if (entry.isIntersecting) {
      this.$refs.sentinelRef.forEach((element, index) => {
        if (element === entry.target) {
          this.index = index;
          this.root.style.setProperty('--current-testimony', index);
        }
      });

      this.$refs.sentinelRef.forEach((element, index) => {
        this.$refs.sentinelTarget[index].classList.remove('is-active');
        this.$refs.sentinelTarget[index].classList.add('is-hidden');

        if (element === entry.target) {
          this.$refs.sentinelTarget[index].classList.add('is-active');
        }

        if (this.index <= index) {
          this.$refs.sentinelTarget[index].classList.remove('is-hidden');
        }
      });
    }
  }
}
